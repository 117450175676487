/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Button, Title, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"ROKLEN10let"}>
        <SiteHeader set="m5rwthlnyrk" currentLanguage={undefined} />

        <Column className="css-1mlozwn --border3 pb--80 pt--02" name={"4h9gdwxa7wm"} parallax={false} border={"3"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/39934/3b14eb735eb346958e4c674be6226b7b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39934/d86c84a98ef24aea95bc466ea7a26385_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":553}} srcSet={"https://cdn.swbpg.com/t/39934/d86c84a98ef24aea95bc466ea7a26385_s=350x_.png 350w, https://cdn.swbpg.com/t/39934/d86c84a98ef24aea95bc466ea7a26385_s=660x_.png 660w, https://cdn.swbpg.com/t/39934/d86c84a98ef24aea95bc466ea7a26385_s=860x_.png 860w, https://cdn.swbpg.com/t/39934/d86c84a98ef24aea95bc466ea7a26385_s=1400x_.png 1400w, https://cdn.swbpg.com/t/39934/d86c84a98ef24aea95bc466ea7a26385_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"backgroundColor":"rgba(0, 0, 0, 1)","marginTop":0,"paddingTop":0,"paddingBottom":0}} layout={"l1"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Button className="btn-box ff--3 fs--16" style={{"maxWidth":341}} content={"Galerie fotek R10"} url={"/galerie-fotek-r10"} href={"/galerie-fotek-r10"} target={"_blank"}>
              </Button>

              <Title className="title-box ff--3 fs--18" content={"<span style=\"color: var(--color-custom-2);\">Děkujeme, že jste 10.výročí skupiny Roklen slavili s námi.&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)","marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --right el--1" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":"","paddingTop":13}}>
              
              <Image style={{"maxWidth":100}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/39934/ab02196e8ffb49b5838ec1be45127715_s=350x_.PNG 350w"} alt={""} src={"https://cdn.swbpg.com/t/39934/ab02196e8ffb49b5838ec1be45127715_s=350x_.PNG"}>
              </Image>

              <Subtitle className="subtitle-box fs--12 lh--16" content={"<span style=\"color: var(--black); font-weight: bold;\">Václavské Nám. 838/9, Praha 1, 110 00<br>roklen10@roklen.cz&nbsp;<br>+420 236 071 600</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}